import * as React from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import Container from "react-bootstrap/Container";
import SEO from "../../components/global/SEO/SEO";
import SignupForm from "../../components/common/SignupForm/SignupForm";

const JoinTravelPassHelpers = (props: any) => {
  const member = useSelector((state) => state.member);
  React.useEffect(() => {
    (async () => {
      if (member.isLoggedIn) {
        navigate("/account", { state: { isAuthenticated: true } });
      }
    })();
  }, [member.isLoggedIn]);

  return (
    <>
      <SEO title={`Join Travelpass`} />
      <Container fluid="sm" className="pt-5 mb-5">
        <SignupForm body={props.page.body.value} isTravelPass={true} />
      </Container>
      {/* <SocialMedia/> */}
    </>
  );
};
export default JoinTravelPassHelpers;
