import * as React from "react";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import { IJoinTravelPassProps } from "../pageHelpers/JoinTravelPass/JoinTravelPassProps";
import JoinTravelPassHelpers from "../pageHelpers/JoinTravelPass/JoinTravelPassHelpers";
import Layout from "../components/global/Layout/Layout";
import { getParagraph } from "../utils/paragraphHelpers";


const JoinTravelPass = (props: IJoinTravelPassProps) => {
  const _site = props.data.allRlhsite.edges[0].node;
  const _paragraphs = [props.data.page.relationships.paragraphs.map(getParagraph)];
  return (
  <Layout {...{site: _site}}>
    <Container>
      <JoinTravelPassHelpers {...props.data} />
      {_paragraphs}
    </Container>
  </Layout>
  );
};
export const pageQuery = graphql`
  query JoinTravelPassQuery {
    page: nodePage(path: { alias: { eq: "/join-travel-pass" } }) {
      id
      title
      field_meta_description
      body {
        value      
      }
      relationships {
        paragraphs: field_sections {
          type: __typename
            ...ParagraphPageBanner
            ...ParagraphPageIntro
            ...ParagraphHistory
            ...ParagraphMediaTiles
            ...ParagraphTeaserList
            ...ParagraphMediaCta
            ...ParagraphOurBrands
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: {machine_name: {eq:"sonesta"}}) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
  }
`;
export default JoinTravelPass;
